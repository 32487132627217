$basewidth: 1170px;

$baseFont : "Open Sans", sans-serif;
$ProximaNova-Extrabld : 'ProximaNova-Extrabld';
$ProximaNova-Semibold : 'ProximaNova-Semibold';
$ProximaNova-Light : 'ProximaNova-Light';
$ProximaNova-Thin : 'ProximaNova-Thin';
$ProximaNova-Regular : 'ProximaNova-Regular';

$infoColor: #65c8e1;
.infoColor {
	color : $infoColor;
}
$dangerColor: #f15b5a;
.dangerColor {
	color : $dangerColor;
}
$darkGray: #4c4d52;
.darkGray {
	color : $darkGray;
}
$textColor : #3a3c41;
.textColor {
	color : $textColor;
}

$pastel : #f8f2ef;

$successColor : #9ccd4b;
$warningColor : #edcf87;