$fonturl :"font/proximanova/";
@font-face {
	font-family: 'ProximaNova-Semibold';
	src: url('#{$fonturl}ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
	url('#{$fonturl}ProximaNova-Semibold.otf')  format('opentype'),
	url('#{$fonturl}ProximaNova-Semibold.woff') format('woff'),
	url('#{$fonturl}ProximaNova-Semibold.ttf')  format('truetype'),
	url('#{$fonturl}ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova-Light';
	src: url('#{$fonturl}ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
	url('#{$fonturl}ProximaNova-Light.otf')  format('opentype'),
	url('#{$fonturl}ProximaNova-Light.woff') format('woff'),
	url('#{$fonturl}ProximaNova-Light.ttf')  format('truetype'),
	url('#{$fonturl}ProximaNova-Light.svg#ProximaNova-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova-Regular';
	src: url('#{$fonturl}ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
	url('#{$fonturl}ProximaNova-Regular.otf')  format('opentype'),
	url('#{$fonturl}ProximaNova-Regular.woff') format('woff'),
	url('#{$fonturl}ProximaNova-Regular.ttf')  format('truetype'),
	url('#{$fonturl}ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova-Thin';
	src: url('#{$fonturl}ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
	url('#{$fonturl}ProximaNovaT-Thin.otf')  format('opentype'),
	url('#{$fonturl}ProximaNovaT-Thin.woff') format('woff'),
	url('#{$fonturl}ProximaNovaT-Thin.ttf')  format('truetype'),
	url('#{$fonturl}ProximaNovaT-Thin.svg#ProximaNova-Thin') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova-Extrabld';
	src: url('#{$fonturl}ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
	url('#{$fonturl}ProximaNova-Extrabld.otf')  format('opentype'),
	url('#{$fonturl}ProximaNova-Extrabld.woff') format('woff'),
	url('#{$fonturl}ProximaNova-Extrabld.ttf')  format('truetype'),
	url('#{$fonturl}ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
	font-weight: normal;
	font-style: normal;
}