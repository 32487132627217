
.container {
	width     : 100%;
	max-width : 100%;
	padding   : 0;
}

.img-center {
	img {
		margin-left : auto;
		margin-right : auto;
	}
}

.am-wrap {
	@include clearfix;
	max-width    : $basewidth;
	width        : 100%;
	margin-left  : auto;
	margin-right : auto;
	display      : block;
}

/**
 * ==================== Header ======================
 * 21.12.2016
 */
.topbar {
	font-size        : 15px;
	color            : #fff;
	font-weight      : 600;
	background-color : $darkGray;
	min-height       : 41px;
	/*Tablet*/
	@media (max-width : 1025px) {
		font-size : 12px;
	}
	p {
		line-height   : 41px;
		margin-bottom : 0;
		opacity       : .8;
	}
	/*Desktop*/
	@media (min-width : 952px) {
		.col-md-8 {
			float      : right;
			text-align : right;
			width      : 100%;
		}
	}
	/*Tablet*/
	@media (max-width : 951px) {
		text-align     : center;
		padding-top    : 10px;
		padding-bottom : 10px;
		p {
			line-height : 20px;
		}
	}
}

nav.navbar {
	background-color : #fff;
	padding-top      : 10px;
	padding-bottom   : 10px;
	border           : none;
	margin           : 0;

	.navbar-brand {
		height  : auto;
		padding : 0 0 0 10px;
	}

	/*Mobile*/
	@media (max-width : 767px) {
		padding-top : 10px;
		.navbar-brand {
			max-width      : 240px;
			padding-bottom : 10px;
		}
	}
	img {
		max-width : 100%;
	}

	li.active {
		a {
			background-color : $infoColor !important;
			color            : #fff !important;
		}
	}
	ul {
		a {
			padding        : 9px 16px;
			text-transform : uppercase;
			font           : normal 14px $ProximaNova-Semibold;
			color          : $textColor !important;
			text-shadow    : none;
			@include transition();
			&:hover {
				background-color : $infoColor !important;
				color            : #fff !important;
			}
		}
	}
	/* Between 768 and 922 */
	@media (min-width : 768px) and (max-width : 932px) {
		.navbar-brand {
			max-width      : 240px;
			padding-bottom : 10px;
		}

	}
	.navbar-nav {
		padding-top : 10px;
	}
}

/**
 * ==================== Slider ======================
 * 21.12.2016
 */
.head-banner {
	position : relative;
	.mainbg {
		width : 100%;
	}
	.slidernavs {
		position         : absolute;
		top              : 50%;
		z-index          : 2;
		display          : block;
		cursor           : pointer;
		border-radius    : 50%;
		width            : 50px;
		height           : 50px;
		background-color : rgba(0, 0, 0, 0.65);
		text-align       : center;
		line-height      : 50px;
		color            : #fff;
		font-size        : 37px;
		margin-top       : -25px;
		opacity          : 0;

		&.shown-arrows {
			opacity : 1;
		}

		&:hover {
			background-color : #000;
		}

		&.prevarrow {
			left : 5px;
		}
		&.nextarrow {
			right : 5px;
		}
		/*Tablet*/
		@media (max-width : 951px) {
			display : none !important;
		}
	}

	.slick-slide {
		&:focus {
			outline    : none;
			box-shadow : none;
		}
	}

	img.mainbg {
		/*Desktop*/
		@media (min-width : 952px) {
			margin-left  : auto;
			margin-right : auto;
		}
		/*Tablet*/
		@media (max-width : 951px) {
			max-width : 100%;
		}
	}

	.words-divider {
		display : block;
	}

	.animated-inner-content {
		@extend .am-wrap;
		position    : absolute;
		display     : flex;
		align-items : center;
		flex-flow   : row wrap;
		height      : 100%;
		hgroup {
			padding-left : 15px !important;
			display      : block;
		}
		/*Desktop*/
		@media (min-width : 1170px) {
			left        : 50%;
			margin-left : -585px;

		}
		/*Tablet*/
		@media (max-width : 951px) {

		}

		h1 {
			margin  : 0;
			font    : normal 44px/49px $ProximaNova-Light;
			display : inline;

			strong {
				font-family : $ProximaNova-Semibold;
			}
		}
		a {
			font             : normal 18px $ProximaNova-Semibold;
			color            : #fff;
			text-transform   : uppercase;
			background-color : $dangerColor;
			border           : none;
			border-radius    : 0;
			padding          : 20px 40px;
			margin-top       : 10px;
			letter-spacing   : 1px;
			@include transition();
			&:hover {
				background-color : darken($dangerColor, 25%);
			}
		}
		/*Tablet*/
		@media (max-width : 951px) {
			hgroup {
				top  : 35px;
				left : 30px;
				h1 {
					font-size   : 30px;
					line-height : 40px;
				}
				a {
					margin-top : 10px;
					padding    : 15px;
				}
			}
		}
		/*Mobile*/
		@media (max-width : 767px) {
			hgroup {
				top  : 15px;
				left : 30px;
				h1 {
					font-size   : 20px;
					line-height : 30px;
				}
				a {
					margin-top : 5px;
					padding    : 2px 20px;
					font-size  : 15px;
				}
			}
		}
	}

}

/**
 * ==================== Site Content ======================
 * 21.12.2016
 */
.site-content {
	padding-bottom : 30px;
	padding-top    : 50px;
	/*Mobile*/
	@media (max-width : 767px) {
		padding-left  : 15px;
		padding-right : 15px;
	}
	h2 {
		font   : normal 40px $ProximaNova-Light !important;
		color  : $textColor;
		margin : 0 0 20px;
		strong {
			font-family : $ProximaNova-Extrabld;
			font-weight : 900 !important;
		}
	}
	p {
		font          : normal 16px/25px $ProximaNova-Regular;
		color         : $textColor;
		margin-bottom : 15px;
		strong {
			font-family : $ProximaNova-Extrabld;
		}
	}

}

/**
 * ==================== Footer ======================
 * 21.12.2016
 */
.faded-text {
	opacity : .6;
}

#site-footer {
	background-color : $textColor;
	padding-top      : 80px;
	color            : #fff;
	a {
		color : #fff;
	}
}

#footer-form-handler {
	margin-top : 15px;
}

#widget-area {
	form {
		display    : block;
		margin-top : 25px;
		input[type=text] {
			border-radius    : 0;
			background-color : #505258;
			border           : none;
			padding          : 12px 15px;
			height           : auto;
		}
		label {
			font  : normal 18px $ProximaNova-Regular;
			color : rgba(255, 255, 255, 0.54);
		}
		button[type=submit] {
			border-radius    : 0;
			font             : normal 18px $ProximaNova-Semibold;
			background-color : $infoColor;
			padding          : 14px 25px;
		}
	}
	> div {
		/*Desktop*/
		@media (min-width : 952px) {
			&:first-of-type {
				.col-md-6 {
					&:first-of-type {
						padding-right : 25px;
					}
					&:last-of-type {
						padding-left : 25px;
					}
				}
			}
		}
	}
	h3 {
		font : normal 23px $ProximaNova-Extrabld;
	}
	ul {
		margin-top : 15px;
		li {
			border-top : 1px solid #4a4b52;
			&:last-of-type {
				border-bottom : 1px solid #4a4b52;
			}
			a {
				display        : block;
				padding        : 15px;
				font           : normal 14px $ProximaNova-Semibold;
				text-transform : uppercase;
				letter-spacing : 1px;
			}
		}
	}
}

.copywright {
	padding-top    : 30px;
	padding-bottom : 30px;
	margin-top     : 120px;
	border-top     : 1px solid #4a4b52;
	ul {
		margin : 0;
		i {
			font-size : 16px;
		}
		a {
			opacity : .5;
			&:hover {
				opacity : 1;
			}
		}
	}
	p {
		margin-bottom : 0;
		font          : normal 16px $ProximaNova-Thin;
		font-weight   : 100;
	}
	.social-col {
		/*Desktop*/
		@media (min-width : 952px) {
			ul {
				float : right;
			}
		}
		/*Mobile*/
		@media (max-width : 767px) {
			margin-top    : 15px;
			margin-bottom : 5px;
		}
	}
	/*Tablet*/
	@media (max-width : 951px) {
		text-align : center;
	}
}

/**
 * ==================== Inner pages ======================
 * 21.12.2016
 */
#mainApp {

	/*Mobile*/
	@media (max-width : 767px) {
		&.services,
		&.patients {
			.head-banner {
				.mainbg {
					max-width : 124%;
				}
			}
		}
	}

	&.patients {
		.site-content {
			padding-left  : 0;
			padding-right : 0;
		}
		/*Desktop*/
		@media (min-width : 952px) {
			.head-banner {
				a {
					margin-top : 8px;
				}
			}
		}
	}

	&.services {

		/*Tablet*/
		@media (max-width : 951px) {
			.head-banner {
				h1 {
					line-height : 26px;
				}
			}
		}

	}

	&.referal-forms,
	&.conditions {
		.site-content {
			padding : 80px 0 80px;
		}
	}

	&.contactus {
		/*Desktop*/
		@media (min-width : 952px) {
			.head-banner {
				hgroup {
					a {
						margin-top : 2px;
					}
				}
			}
		}
		.site-content {
			padding-top    : 0;
			padding-bottom : 0;

			h1 {
				font : normal 43px $ProximaNova-Light;
			}

			.form-content {
				padding-top    : 40px;
				padding-bottom : 80px;
			}

			@include placeholder-color($textColor);

			.select-wrapper {
				overflow-x : hidden;
			}

			select {
				width         : 110%;
				border        : none;
				border-radius : 0;
				background    : #f7f7f7 url("../img/select-dropper.jpg") no-repeat right 14% center;
				box-shadow    : none;
				font          : normal 18px $ProximaNova-Semibold;
				color         : $textColor;
				height        : 60px;
				text-indent   : 13px;
			}

			/*Mobile*/
			@media (max-width : 767px) {
				select {
					width            : 100%;
					background-image : none;
				}
			}

			textarea,
			input[type=text],
			input[type=email] {
				border           : none;
				border-radius    : 0;
				background-color : #f7f7f7;
				box-shadow       : none;
				font             : normal 18px $ProximaNova-Semibold;
				color            : $textColor;
				padding          : 19px 25px;
				height           : auto;
			}
			input[type=text],
			input[type=email] {
				height : 60px;
			}

			textarea {
				resize        : vertical;
				min-height    : 180px;
				margin-bottom : 10px;
			}

			button[type=submit] {
				@extend .redbutton;
				/*Desktop*/
				@media (min-width : 952px) {
					padding : 20px 40px;
					float   : right;
				}
			}

			.req-notice {
				margin-bottom : 0;
				font-family   : $ProximaNova-Semibold;
			}

			aside {
				padding-left     : 50px;
				padding-top      : 150px;
				background-color : #f9f9f9;
				padding-bottom   : 50px;
				hgroup {
					margin-bottom : 25px;
				}
				h3 {
					font   : normal 16px $ProximaNova-Extrabld;
					margin : 0 0 10px;
				}
				a {
					font : normal 16px $ProximaNova-Extrabld;
				}
			}
		}
	}
}

.panel-offer {
	h1 {
		@include clearfix;
		margin-top    : 20px;
		margin-bottom : 10px;
		font          : normal 24px/60px $ProximaNova-Extrabld;
		display       : block;
		i {
			margin-right        : 25px;
			display             : inline-block;
			width               : 60px;
			height              : 60px;
			float               : left;
			background-position : center;
			background-repeat   : no-repeat;

			&.data-hands {
				background-image : url("../img/hands-point.png");
			}

			&.data-plus {
				background-image : url("../img/plus-point.png");
			}

			&.data-folowup {
				background-image : url("../img/followup-point.png");
			}

			&.data-convinientt-access {
				background-image : url("../img/convinient-point.png");
			}

			&.data-medication-delivery {
				background-image : url("../img/medication-point.png");
			}

			&.data-financial {
				background-image : url("../img/financial-point.png");
			}

			&.data-education {
				background-image : url("../img/education-point.png");
			}

			&.data-communication {
				background-image : url("../img/commnunication-point.png");
			}

			&.data-assessment {
				background-image : url("../img/assessment-point.png");
			}

		}
	}
}

/**
 * ==================== Additions ======================
 * 21.12.2016
 */
h3.bold {
	font : normal 24px $ProximaNova-Extrabld;
}

.darkblue {
	color : #0a4c84;
}

.pharmalistwrap {
	/*Desktop*/
	@media (min-width : 952px) {
		text-align   : center;
		padding-left : 0;
		ul {
			padding-left : 0;
			display      : inline-block;
			li {
				text-align    : left;
				font-size     : 18px;
				margin-bottom : 5px;
				&:before {
					width  : 11px;
					height : 11px;
					top    : 8px;
					left   : -8px;
				}
			}
		}
	}
}

.pharmalist {
	margin-top    : 20px;
	margin-bottom : 80px;
	li {
		position     : relative;
		padding-left : 20px;
		font         : normal 16px/30px $ProximaNova-Regular;
		&:before {
			content          : "";
			display          : block;
			position         : absolute;
			width            : 8px;
			height           : 8px;
			left             : 0;
			top              : 10px;
			border-radius    : 50%;
			background-color : $infoColor;
		}
	}
}

.condition-list {
	li {
		border-top : 1px dotted $textColor;
		padding    : 8px 15px;
		&:last-of-type {
			border-bottom : 1px dotted $textColor;
		}
		a {
			font  : normal 24px $ProximaNova-Semibold;
			color : $infoColor;
			&:hover {
				text-decoration : none;
				color           : $dangerColor;
			}
			i {
				float      : right;
				color      : $textColor;
				margin-top : 3px;
				opacity    : .6;
			}
		}
	}
}

/**
 * ==================== Flex Sidebar ======================
 * 23.12.2016
 */
.right-sidebar {
	.site-content {
		display   : flex;
		flex-flow : row wrap;
		.col-md-8 {
			float      : none;
			flex-basis : 67%;
		}
		.col-md-4 {
			float       : none;
			margin-left : auto;
			flex-basis  : 30%;
		}
	}
	/*Mobile*/
	@media (max-width : 767px) {
		.col-md-4 {
			padding : 20px !important;
		}
		[class*="col-md"] {
			flex-basis : 100% !important;
		}
	}
}

.referal-forms {
	.head-banner {
		/*Desktop*/
		@media (min-width : 952px) {
			hgroup {
				top : 130px;
			}
		}
	}
}

h2.thin {
	margin-bottom : 0;
	font-family   : $ProximaNova-Regular;
}

.single-condition {

	.col-md-12 {
		h1, h2 {
			font          : normal 40px $ProximaNova-Light;
			margin-bottom : 5px;
		}
	}

	ul, ol {
		padding-left : 15px;
	}

	li {
		font  : normal 16px/30px $ProximaNova-Regular;
		color : $textColor;
	}

	.site-content {
		a {
			color : $dangerColor;
		}
	}

}

.back-button {
	font : normal 18px $ProximaNova-Semibold;
	&:hover {
		color : #000;
	}
}

.aside-conditions {
	padding : 0 !important;

	a {
		&:not(.active) {
			color : $infoColor !important;
		}
		&:hover {
			color : $dangerColor !important;
		}
	}
}

/**
 * ==================== Responsiveness ======================
 * 23.12.2016
 */
#mainApp {
	/* IPAD Landscape */
	@media (min-width : 980px) and (max-width : 1024px) {
		.site-content {
			padding-left  : 15px;
			padding-right : 15px;
		}
		.head-banner {
			.animated-inner-content hgroup {
				padding-left : 15px;
			}
			.mainbg {
				//margin-left : -450px;
			}
		}
	}

	/* IPAD Portrait*/
	@media (min-width : 768px) and (max-width : 979px) {
		.site-content {
			padding-left  : 15px;
			padding-right : 15px;
		}
		&.services {
			.head-banner {
				h1 {
					line-height : 40px;
				}
			}
		}
		&.conditions,
		&.referal-forms {
			.site-content {
				padding-top    : 50px;
				padding-bottom : 20px;
			}
			.col-md-8 {
				margin-top : 30px;
			}
			.condition-list {
				margin-bottom : 20px;
			}
		}
	}
}

.border-control {
	margin-bottom : 0 !important;
	/*Tablet*/
	@media (max-width : 951px) {
		margin-top : 10px;
		li:last-of-type {
			border-bottom : none;
		}
	}
}

/*Desktop*/
@media (min-width : 952px) {
	.single-condition {
		.site-content {
			> .col-md-4 {
				float : left !important;
			}
			> .col-md-8 {
				float : right !important;
			}
		}
	}
}

/*Tablet*/
@media (max-width : 951px) {
	.container {
		&.conditions {
			.site-content {
				padding-bottom : 50px !important;
				padding-top    : 50px !important;
			}
		}
	}
}

#arrow-scroll {
	display               : none;
	position              : fixed;
	z-index               : 9999;
	bottom                : 0px;
	right                 : 30px;
	width                 : 70px;
	height                : 70px;
	margin-bottom         : -10px;
	padding               : 10px 5px;
	font-family           : sans-serif;
	font-size             : 14px;
	line-height           : 20px;
	text-align            : center;
	text-decoration       : none;
	text-shadow           : 0 1px 0 #fff;
	color                 : #828282;
	text-decoration       : none !important;
	-webkit-border-radius : 3px;
	-moz-border-radius    : 3px;
	-o-border-radius      : 3px;
	-ms-border-radius     : 3px;
	border-radius         : 3px 3px 0 0;
	-webkit-box-shadow    : 0 0px 2px 1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow       : 0 0px 2px 1px rgba(0, 0, 0, 0.2);
	box-shadow            : 0 0px 2px 1px rgba(0, 0, 0, 0.2);
	background-color      : #E6E6E6;
	background-image      : -moz-linear-gradient(top, #ebebeb, #dedede);
	background-image      : -webkit-gradient(linear, 0 0, 0 100%, from(#ebebeb), to(#dedede));
	background-image      : -webkit-linear-gradient(top, #ebebeb, #dedede);
	background-image      : -o-linear-gradient(top, #ebebeb, #dedede);
	background-image      : linear-gradient(to bottom, #ebebeb, #dedede);
	background-repeat     : repeat-x;
	-webkit-transition    : margin-bottom 150ms linear;
	-moz-transition       : margin-bottom 150ms linear;
	transition            : margin-bottom 150ms linear;

	display               : none;
	&:hover {
		opacity       : 1;
		margin-bottom : -1px;
		color         : #000;
	}
	&.show {
		display : block;
	}
}

#mc-embedded-subscribe-form {
	input[type='text'],
	input[type='email'] {
		color : #fff;
	}
}

.site-content {
	.black-font {
		font-family : $ProximaNova-Extrabld !important;
	}
}

.indexpage {
	/*Desktop*/
	@media (min-width : 952px) {
		.head-banner {
			hgroup {
				h1 {
					font : normal 48px/50px $ProximaNova-Light;
				}
			}
		}
	}
}

.whitecolor {
	color : #fff !important;
	* {
		color : #fff !important;
	}
}

.contacts-lines {
	/*Tablet*/
	@media (max-width : 951px) {
		padding : 0;
		p {
			opacity : .9;
		}
	}
}

.desktop-hidden {
	/*Desktop*/
	@media (min-width : 952px) {
		display : none !important;
	}
}

.tablet-hidden {
	/*Tablet*/
	@media (max-width : 951px) {
		display : none;
	}
}

.pharmaword {
	font-family : $ProximaNova-Regular;
	//color       : #075596;
	.infoColor {
		color : $textColor;
	}
}